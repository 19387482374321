/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';



//font-awesome
$fa-font-path: '/assets/fonts' !default;
@import '~@stg-js-ngx-core/metronics/assets/sass/font-awesome.scss';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto, " Helvetica Neue", sans-serif";
  // color: #333
  color: #334e4f !important;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

//Overriding bootstrap button design issue
.btn-primary {
  background-color: #70C1C0 !important;
  border-color: #70C1C0 !important;
  color: #ffffff !important;
}

.kt-header--fixed {
  height: 55px !important;
}

.kt-header__topbar-user {
  img {
    border-radius: 25px;
  }
}

.kt-portlet__head {
  flex-wrap: wrap;

  .kt-portlet__head-title {
    color: #70C1C0 !important;
    font-weight: 600 !important;

    .head-title-note {
      color: black !important;
      font-size: 12px !important;
      font-weight: normal !important;
    }
  }
}

.kt-font-prop {
  color: #334e4f !important;
  font-weight: 600 !important;
}

.common-padding-5 {
  padding: 5px !important;
}

.common-padding-pct-1 {
  padding: 1% !important;
}

.common-error-span {
  color: red;
  font-weight: 500;

}

.common-font-large {
  font-size: large !important;
}

.common-font-medium {
  font-size: medium !important;
}

.common-font-small {
  font-size: small !important;
}

.common-float-right {
  float: right !important;
}


.errorSpan {
  color: red !important;
}

.custom-file-control {
  width: 30vw;
}

.print-button {
    background-color: #70C1C0 !important;
    color: rgb(255, 255, 255);
    border: 2px solid #70C1C0;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.print-button:hover {
    background-color: #4e8c8c !important;
}

.print-icon {
    font-size: 1.5em;
    margin-right: 8px;
}

.dynamic-margin-left {
  margin-left: 0;
  /* Default to 0 for small screens */

  @media (min-width: 768px) {
    /* Medium screens and up */
    margin-left: 33.33%;
    /* Equivalent to col-md-4 */
  }

  @media (min-width: 992px) {
    /* Large screens and up */
    margin-left: 33.33%;
    /* Equivalent to col-lg-4 */
  }
}

.ng-select {
  .ng-select-container {
    font-size: 14px;
    font-weight: 600;
    padding-left: 0.75em;

    .ng-value-label,
    .ng-value {
      font-weight: normal;
    }

    .ng-placeholder {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }

  .ng-select-container.ng-has-value {

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      border-top: 1.84375em solid transparent !important;
    }

    .ng-value-container {
      padding-top: 1.1875em !important;
    }
  }

  .ng-select-container:after {
    border-bottom: thin solid rgba(216, 220, 230, 0.42) !important;
  }
}

.upload-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .upload-input {
    max-width: 48%;
    margin-right: 10px;
  }

  .editDiv {
    width: 100% !important;
  }
}

.common-page-header-label {
  color: #70C1C0 !important;
  font-weight: 600 !important;
  font-size: medium
}


.mat-form-field-appearance-fill {
  font-size: 15px !important;
  width: 100% !important;

  .mat-button-wrapper {
    position: absolute;
    top: 8px;
  }

  .mat-form-field-label {
    font-weight: 600;
  }
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label:not(.mat-empty):not(.mat-form-field-empty) {
  color: #70C1C0;
  font-size: 20px;
  margin-top: -0.6em;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 10px !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: rgb(175 175 175 / 42%) !important;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgb(0 0 0 / 60%);
}

.mat-error {
  font-size: 13px;
}

.underline-block {
  padding-top: 1px;
  background-color: #adb5bd;
}

.home-body {
  .subscription {
    height: 20vh !important;
  }

  .facility-news {
    height: 40vh !important;
    overflow-y: auto;
  }

  .documents-upload {
    height: 60vh !important;
  }

  .system-news {
    height: 50vh !important;
    overflow-y: auto;
  }
}

.spin-wrapper {
  z-index: 999 !important;
}

.ag-floating-filter-full-body input {
  background-image: url(/assets/images/magnify.png) !important;
}

.ag-grid-cell-wrap {
  white-space: normal !important;
}

.ag-status-bar {
  padding: 0 !important;
}

.grid-icon-button {
  line-height: 0 !important;
  height: auto !important;
  width: 100% !important;
  border: none;
  background: transparent;
}

.modal-header {
  .mat-icon-button {
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: none;
    background: transparent;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 10px !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: rgb(175 175 175 / 42%) !important;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgb(0 0 0 / 60%);
}

.mat-error {
  font-size: 13px;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgb(141 141 141 / 23%) !important;
}

.mat-form-field-required-marker {
  color: #fd397a;
}

.mat-form-field-appearance-fill {
  font-size: 15px !important;
  width: 100% !important;

  .mat-button-wrapper {
    position: absolute;
    top: 8px;
  }

  .mat-form-field-label {
    font-weight: 600;
  }
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label:not(.mat-empty):not(.mat-form-field-empty) {
  color: #70C1C0;
  font-size: 20px;
  margin-top: -0.6em;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgb(229 229 229 / 23%) !important;
}


.ng-select .ng-select-container.ng-has-value .ng-placeholder {
  color: #70C1C0 !important;
  font-size: 20px;
}

.ng-select.ng-select-disabled .ng-select-container:after {
  background-image: none !important;
}

.ng-select.ng-select-disabled .ng-select-container {
  background-color: rgb(141 141 141 / 23%) !important;
  border-radius: 5px 5px 0 0;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  color: rgba(0, 0, 0, 0.6) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid #ccc !important;

  .ng-value-icon {
    color: #333 !important;
  }
}

.ng-select-required {
  .ng-placeholder {
    padding-right: 12px;
  }

  .ng-placeholder::before {
    content: "*";
    font-size: 16px;
    color: #fd397a;
    right: 0;
    position: absolute;
  }
}